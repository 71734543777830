fieldset {
    font-size: 22px;
}

.icon-tw {
    background-color: #4a4a4a;
}

.icon-tw:hover {
    background-color: #55acee;
}

.icon-you {
    background-color: #4a4a4a;
}

.icon-you:hover {
    background-color: #FF0000;
}

.icon-kedin {
    background-color: #4a4a4a;
}

.icon-kedin:hover {
    background-color: #0A66C2;
}